<template>
  <b-row>
    <b-col cols="12">
      <ServerComponent />
    </b-col>
  </b-row>
</template>

<script>
import { BCol, BRow } from 'bootstrap-vue'
import ServerComponent from './Server/ServerComponent.vue'

export default {
  name: 'TabServerComponent',
  components: {
    BRow,
    BCol,
    ServerComponent,
  },
}
</script>

<style scoped>

</style>
