<template>
  <validation-observer ref="formValidation">
    <b-form>
      <b-row>
        <b-col
          sm="12"
        >
          <div class="alert bg-primary p-1">
            <div class="position-relative d-inline text-white">
              <feather-icon icon="AlertCircleIcon" />
            </div>
            <div class="position-relative d-inline text-white">
              Registre sucursales para asociar dispositivos a una sucursal del cliente
            </div>
          </div>
        </b-col>
        <b-col
          sm="12"
        >
          <b-form-group
            label="Nombre del servidor"
            label-for="server_name"
          >
            <validation-provider
              #default="{ errors }"
              name="nombre del servidor"
              rules="required"
            >
              <b-form-input
                id="server_name"
                v-model="server_name"
                placeholder="Nombre del servidor"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-col>
        <b-col
          lg="6"
          md="6"
          sm="12"
        >
          <b-form-group
            label="Ip pública"
            label-for="public_ip"
          >
            <validation-provider
              #default="{ errors }"
              name="Ip pública"
              rules="required"
            >
              <b-form-input
                id="public_ip"
                v-model="public_ip"
                placeholder="Ip pública"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-col>
        <b-col
          lg="6"
          md="6"
          sm="12"
        >
          <b-form-group
            label="Ip privada"
            label-for="private_ip"
          >
            <validation-provider
              #default="{ errors }"
              name="Ip privada"
              rules="required"
            >
              <b-form-input
                id="private_ip"
                v-model="private_ip"
                placeholder="Ip privada"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-col>
        <b-col
          lg="6"
          md="6"
          sm="12"
        >
          <b-form-group
            label="Sistema operativo"
            label-for="so"
          >
            <validation-provider
              #default="{ errors }"
              name="sistema operativo"
              rules="required"
            >
              <b-form-input
                id="so"
                v-model="so"
                placeholder="Sistema operativo"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-col>
        <b-col
          lg="6"
          md="6"
          sm="12"
        >
          <b-form-group
            label="Memoria"
            label-for="memory"
          >
            <validation-provider
              #default="{ errors }"
              name="memoria"
              rules="required"
            >
              <b-form-input
                id="memory"
                v-model="memory"
                placeholder="Memoria"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-col>
        <b-col
          lg="6"
          md="6"
          sm="12"
        >
          <b-form-group
            label="Disco"
            label-for="disc"
          >
            <validation-provider
              #default="{ errors }"
              name="disco"
              rules="required"
            >
              <b-form-input
                id="disc"
                v-model="disc"
                placeholder="Disco"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-col>
        <b-col
          lg="6"
          md="6"
          sm="12"
        >
          <b-form-group
            label="Modelo del servidor"
            label-for="server_model"
          >
            <validation-provider
              #default="{ errors }"
              name="modelo del servidor"
              rules="required"
            >
              <b-form-input
                id="server_model"
                v-model="server_model"
                placeholder="Modelo del servidor"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-col>
        <b-col
          lg="12"
          md="12"
          sm="12"
        >
          <b-form-group
            label="RDWeb"
            label-for="rd_web"
          >
            <validation-provider
              #default="{ errors }"
              name="rdweb"
              rules="required"
            >
              <b-form-input
                id="rd_web"
                v-model="rd_web"
                placeholder="RDWeb"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-col>
        <b-col
          lg="12"
          md="12"
          sm="12"
        >
          <b-form-group
            label="Notas"
            label-for="notes"
          >
            <b-form-textarea
              id="notes"
              v-model="notes"
              placeholder="Notas"
              rows="3"
            />
          </b-form-group>
        </b-col>
        <b-col
          lg="8"
          md="10"
          sm="12"
        >
          <b-button
            class="float-right text-right"
            variant="primary"
            type="submit"
            @click.prevent="onHandleValidationForm"
          >
            <feather-icon
              icon="SaveIcon"
              class="mr-50"
            />
            Guardar
          </b-button>
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="secondary"
            class="float-right mr-1"
            @click="closeForm"
          >
            Cancelar
          </b-button>
        </b-col>
      </b-row>
    </b-form>
  </validation-observer>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  BRow,
  BCol,
  BButton,
  BFormGroup,
  BFormInput,
  BForm,
  BFormTextarea,
} from 'bootstrap-vue'
import { required } from '@validations'
import errorsServices from '@/libs/errorsServices'
import permissions from '@/libs/permissions'

export default {
  name: 'ServerFormComponent',
  components: {
    ValidationProvider,
    ValidationObserver,
    BRow,
    BCol,
    BButton,
    BFormGroup,
    BFormInput,
    BForm,
    BFormTextarea,
  },
  data() {
    return {
      server_name: '',
      public_ip: '',
      private_ip: '',
      so: '',
      cpu: '',
      memory: '',
      disc: '',
      server_model: '',
      rd_web: '',
      notes: '',
      required,
    }
  },
  created() {
    this.onHandleEdit()
  },
  methods: {
    accessPermissions(permission) {
      return permissions(permission)
    },
    errorResp(error) {
      const err = errorsServices(error)

      this.swal(err, 'error')
    },
    clearForm() {
      this.server_name = ''
      this.public_ip = ''
      this.private_ip = ''
      this.so = ''
      this.cpu = ''
      this.memory = ''
      this.disc = ''
      this.server_model = ''
      this.rd_web = ''
      this.notes = ''
      this.$refs.formValidation.reset()
    },
    closeForm() {
      this.clearForm()
      this.$emit('setHiddenModal')
    },
    onHandleEdit() {

    },
    async onHandleValidationForm() {
      await this.$refs.formValidation.validate().then(success => {
        if (success) {
        }
      })
    },
  },
}
</script>

<style scoped>

</style>