<template>
  <validation-observer ref="formValidation">
    <b-form>
      <b-row class="align-content-center mx-auto d-flex flex-column">
        <b-col
          lg="8"
          md="10"
          sm="12"
        >
          <b-form-group
            label="Nombre"
            label-for="full_name"
          >
            <validation-provider
              #default="{ errors }"
              name="nombre"
              rules="required"
            >
              <b-form-input
                id="full_name"
                v-model="full_name"
                :state="errors.length > 0 ? false:null"
                placeholder="Nombre"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-col>
        <b-col
          lg="8"
          md="10"
          sm="12"
        >
          <b-form-group
            label="Identificación"
            label-for="dni"
          >
            <validation-provider
              #default="{ errors }"
              name="dni"
              rules="required"
            >
              <b-form-input
                id="dni"
                v-model="dni"
                placeholder="Identificación"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-col>
        <b-col
          lg="8"
          md="10"
          sm="12"
        >
          <b-form-group
            label="Teléfono"
            label-for="phone"
          >
            <validation-provider
              #default="{ errors }"
              name="teléfono"
              rules="required"
            >
              <b-form-input
                id="phone"
                v-model="phone"
                placeholder="Teléfono"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-col>
        <b-col
          lg="8"
          md="10"
          sm="12"
        >
          <b-form-group
            label="Dirección"
            label-for="address"
          >
            <b-form-textarea
              id="address"
              v-model="address"
              placeholder="Dirección"
              rows="3"
            />
          </b-form-group>
        </b-col>
        <b-col
          lg="8"
          md="10"
          sm="12"
        >
          <b-button
            class="float-right text-right"
            variant="primary"
            type="submit"
            @click.prevent="onHandleValidationForm"
          >
            <feather-icon
              icon="SaveIcon"
              class="mr-50"
            />
            Guardar
          </b-button>
        </b-col>
      </b-row>
    </b-form>
  </validation-observer>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  BRow,
  BCol,
  BButton,
  BFormGroup,
  BFormInput,
  BForm,
  BFormTextarea,
} from 'bootstrap-vue'
import { required } from '@validations'
import errorsServices from '@/libs/errorsServices'
import permissions from '@/libs/permissions'

export default {
  name: 'DataComponent',
  components: {
    ValidationProvider,
    ValidationObserver,
    BRow,
    BCol,
    BButton,
    BFormGroup,
    BFormInput,
    BForm,
    BFormTextarea,
  },
  data() {
    return {
      full_name: '',
      dni: '',
      phone: '',
      address: '',
      required,
    }
  },
  created() {
    this.onHandleEdit()
  },
  methods: {
    accessPermissions(permission) {
      return permissions(permission)
    },
    errorResp(error) {
      const err = errorsServices(error)

      this.swal(err, 'error')
    },
    clearForm() {
      this.full_name = ''
      this.dni = ''
      this.phone = ''
      this.address = ''
      this.$refs.formValidation.reset()
    },
    onHandleEdit() {

    },
    async onHandleValidationForm() {
      await this.$refs.formValidation.validate().then(success => {
        if (success) {
        }
      })
    },
  },
}
</script>

<style scoped>
</style>
