<template>
  <validation-observer ref="formValidation">
    <b-form>
      <b-row class="align-content-center mx-auto d-flex flex-column">
        <b-col
          lg="8"
          md="10"
          sm="12"
        >
          <div class="alert bg-primary p-1">
            <div class="position-relative d-inline text-white">
              <feather-icon icon="AlertCircleIcon" />
            </div>
            <div class="position-relative d-inline text-white">
              Registre sucursales para asociar dispositivos a una sucursal del cliente
            </div>
          </div>
        </b-col>
        <b-col
          lg="8"
          md="10"
          sm="12"
        >
          <b-form-group
            label="Tipo de dispositivo"
            label-for="device"
          >
            <validation-provider
              #default="{ errors }"
              name="tipo de dispositivo"
              rules="required"
            >
              <v-select
                v-model="device"
                :state="errors.length > 0 ? false:null"
                :dir="'ltr'"
                :options="rowsDevices"
                label="full_name"
              >
                <span
                  v-if="rowsDevices.length > 0"
                  slot="no-options"
                >No hay data para cargar</span>
              </v-select>
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-col>
        <b-col
          lg="8"
          md="10"
          sm="12"
        >
          <b-form-group
            label="Marca"
            label-for="brand"
          >
            <validation-provider
              #default="{ errors }"
              name="marca"
              rules="required"
            >
              <v-select
                v-model="brand"
                :state="errors.length > 0 ? false:null"
                :dir="'ltr'"
                :options="rowsBrands"
                label="full_name"
              >
                <span
                  v-if="rowsBrands.length > 0"
                  slot="no-options"
                >No hay data para cargar</span>
              </v-select>
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-col>
        <b-col
          lg="8"
          md="10"
          sm="12"
        >
          <b-form-group
            label="IP"
            label-for="ip"
          >
            <validation-provider
              #default="{ errors }"
              name="ip"
              rules="required"
            >
              <b-form-input
                id="ip"
                v-model="ip"
                placeholder="IP"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-col>
        <b-col
          lg="8"
          md="10"
          sm="12"
        >
          <b-form-group
            label="Nombre"
            label-for="name"
          >
            <validation-provider
              #default="{ errors }"
              name="name"
              rules="required"
            >
              <b-form-input
                id="name"
                v-model="name"
                placeholder="Nombre"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-col>
        <b-col
          lg="8"
          md="10"
          sm="12"
        >
          <b-form-group
            label="Características"
            label-for="features"
          >
          </b-form-group>
        </b-col>
        <b-col
          lg="8"
          md="10"
          sm="12"
        >
          <b-button
            class="float-right text-right"
            variant="primary"
            type="submit"
            @click.prevent="onHandleValidationForm"
          >
            <feather-icon
              icon="SaveIcon"
              class="mr-50"
            />
            Guardar
          </b-button>
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="secondary"
            class="float-right mr-1"
            @click="closeForm"
          >
            Cancelar
          </b-button>
        </b-col>
      </b-row>
    </b-form>
  </validation-observer>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  BRow,
  BCol,
  BButton,
  BFormGroup,
  BFormInput,
  BForm,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import { required } from '@validations'
import errorsServices from '@/libs/errorsServices'
import permissions from '@/libs/permissions'

export default {
  name: 'DeviceFormComponent',
  components: {
    vSelect,
    ValidationProvider,
    ValidationObserver,
    BRow,
    BCol,
    BButton,
    BFormGroup,
    BFormInput,
    BForm,
  },
  data() {
    return {
      device: '',
      brand: '',
      ip: '',
      name: '',
      rowsDevices: [],
      rowsBrands: [],
      required,
    }
  },
  created() {
    this.onHandleEdit()
  },
  methods: {
    accessPermissions(permission) {
      return permissions(permission)
    },
    errorResp(error) {
      const err = errorsServices(error)

      this.swal(err, 'error')
    },
    clearForm() {
      this.device = ''
      this.brand = ''
      this.ip = ''
      this.name = ''
      this.rowsDevices = []
      this.rowsBrands = []
      this.$refs.formValidation.reset()
    },
    closeForm() {
      this.clearForm()
      this.$emit('setHiddenModal')
    },
    onHandleEdit() {

    },
    async onHandleValidationForm() {
      await this.$refs.formValidation.validate().then(success => {
        if (success) {
        }
      })
    },
  },
}
</script>

<style scoped>

</style>