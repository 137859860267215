<template>
  <b-row>
    <b-col cols="12">
      <MailComponent />
    </b-col>
  </b-row>
</template>

<script>
import { BCol, BRow } from 'bootstrap-vue'
import MailComponent from './Mail/MailComponent.vue'

export default {
  name: 'TabMailComponent',
  components: {
    BRow,
    BCol,
    MailComponent,
  },
}
</script>

<style scoped>

</style>
