var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('validation-observer',{ref:"formValidation"},[_c('b-form',[_c('b-row',{staticClass:"align-content-center mx-auto d-flex flex-column"},[_c('b-col',{attrs:{"lg":"8","md":"10","sm":"12"}},[_c('div',{staticClass:"alert bg-primary p-1"},[_c('div',{staticClass:"position-relative d-inline text-white"},[_c('feather-icon',{attrs:{"icon":"AlertCircleIcon"}})],1),_c('div',{staticClass:"position-relative d-inline text-white"},[_vm._v(" Registre sucursales para asociar dispositivos a una sucursal del cliente ")])])]),_c('b-col',{attrs:{"lg":"8","md":"10","sm":"12"}},[_c('b-form-group',{attrs:{"label":"Proveedor de correo","label-for":"prvider"}},[_c('validation-provider',{attrs:{"name":"proveedor de correo","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"state":errors.length > 0 ? false:null,"dir":'ltr',"options":_vm.rowsProvides,"label":"full_name"},model:{value:(_vm.provider),callback:function ($$v) {_vm.provider=$$v},expression:"provider"}},[(_vm.rowsProvides.length > 0)?_c('span',{attrs:{"slot":"no-options"},slot:"no-options"},[_vm._v("No hay data para cargar")]):_vm._e()]),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"lg":"8","md":"10","sm":"12"}},[_c('b-form-group',{attrs:{"label":"Nombre del servidor","label-for":"server_name"}},[_c('validation-provider',{attrs:{"name":"nombre del servidor","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"server_name","placeholder":"Nombre del servidor"},model:{value:(_vm.server_name),callback:function ($$v) {_vm.server_name=$$v},expression:"server_name"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"lg":"8","md":"10","sm":"12"}},[_c('b-form-group',{attrs:{"label":"Email","label-for":"email"}},[_c('validation-provider',{attrs:{"name":"email","rules":"required|email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"state":errors.length > 0 ? false:null,"type":"email","placeholder":"Email"},model:{value:(_vm.emailValue),callback:function ($$v) {_vm.emailValue=$$v},expression:"emailValue"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"lg":"8","md":"10","sm":"12"}},[_c('b-form-group',[_c('div',{staticClass:"d-flex justify-content-between"},[_c('label',[_vm._v("Contraseña")])]),_c('validation-provider',{attrs:{"name":"contraseña","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-input-group',{staticClass:"input-group-merge"},[_c('b-form-input',{staticClass:"form-control-merge",attrs:{"type":_vm.passwordFieldType,"name":"password","placeholder":"············"},model:{value:(_vm.password),callback:function ($$v) {_vm.password=$$v},expression:"password"}}),_c('b-input-group-append',{attrs:{"is-text":""}},[_c('feather-icon',{staticClass:"cursor-pointer",attrs:{"icon":_vm.passwordToggleIcon},on:{"click":_vm.togglePasswordVisibility}})],1)],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"lg":"8","md":"10","sm":"12"}},[_c('b-form-group',{attrs:{"label":"Nombre (opcional)","label-for":"name"}},[_c('validation-provider',{attrs:{"name":"name","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"name","placeholder":"Nombre"},model:{value:(_vm.name),callback:function ($$v) {_vm.name=$$v},expression:"name"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"lg":"8","md":"10","sm":"12"}},[_c('b-form-group',{attrs:{"label":"Notas","label-for":"notes"}},[_c('b-form-textarea',{attrs:{"id":"notes","placeholder":"Notas","rows":"3"},model:{value:(_vm.notes),callback:function ($$v) {_vm.notes=$$v},expression:"notes"}})],1)],1),_c('b-col',{attrs:{"lg":"8","md":"10","sm":"12"}},[_c('b-button',{staticClass:"float-right text-right",attrs:{"variant":"primary","type":"submit"},on:{"click":function($event){$event.preventDefault();return _vm.onHandleValidationForm.apply(null, arguments)}}},[_c('feather-icon',{staticClass:"mr-50",attrs:{"icon":"SaveIcon"}}),_vm._v(" Guardar ")],1),_c('b-button',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(255, 255, 255, 0.15)'),expression:"'rgba(255, 255, 255, 0.15)'",modifiers:{"400":true}}],staticClass:"float-right mr-1",attrs:{"variant":"secondary"},on:{"click":_vm.closeForm}},[_vm._v(" Cancelar ")])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }