<template>
  <b-card-code>
    <b-card-body>
      <b-row>
        <b-col cols="12">
          <b-tabs justified>
            <b-tab>
              <template #title>
                <feather-icon icon="ClipboardIcon" />
                <span>Datos Generales</span>
              </template>

              <TabGeneralDataComponent />
            </b-tab>

            <b-tab>
              <template #title>
                <feather-icon icon="SmartphoneIcon" />
                <span>Dispositivos</span>
              </template>

              <TabDeviceComponent />
            </b-tab>

            <b-tab>
              <template #title>
                <feather-icon icon="MailIcon" />
                <span>Correos</span>
              </template>

              <TabMailComponent />
            </b-tab>

            <b-tab>
              <template #title>
                <feather-icon icon="EditIcon" />
                <span>Notas</span>
              </template>

              <TabNoteComponent />
            </b-tab>

            <b-tab>
              <template #title>
                <feather-icon icon="ServerIcon" />
                <span>Servidores</span>
              </template>

              <TabServerComponent />
            </b-tab>

            <b-tab>
              <template #title>
                <feather-icon icon="FileTextIcon" />
                <span>Documentos</span>
              </template>

              <TabDocumentComponent />
            </b-tab>
          </b-tabs>
        </b-col>
      </b-row>
    </b-card-body>
  </b-card-code>
</template>

<script>
import {
  BCardBody,
  BRow,
  BCol,
  BTabs,
  BTab,
} from 'bootstrap-vue'
import BCardCode from '@core/components/b-card-code/BCardCode.vue'
import TabGeneralDataComponent from './components/TabGeneralDataComponent.vue'
import TabDeviceComponent from './components/TabDeviceComponent.vue'
import TabMailComponent from './components/TabMailComponent.vue'
import TabServerComponent from './components/TabServerComponent.vue'
import TabDocumentComponent from './components/TabDocumentComponent.vue'
import TabNoteComponent from './components/TabNoteComponent.vue'

export default {
  name: 'PasswordPage',
  components: {
    BCardBody,
    BRow,
    BCol,
    BTabs,
    BTab,
    BCardCode,
    TabGeneralDataComponent,
    TabDeviceComponent,
    TabMailComponent,
    TabServerComponent,
    TabDocumentComponent,
    TabNoteComponent,
  },
}
</script>

<style scoped>

</style>