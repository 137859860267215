var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('validation-observer',{ref:"formValidation"},[_c('b-form',[_c('b-row',{staticClass:"align-content-center mx-auto d-flex flex-column"},[_c('b-col',{attrs:{"lg":"8","md":"10","sm":"12"}},[_c('div',{staticClass:"alert bg-primary p-1"},[_c('div',{staticClass:"position-relative d-inline text-white"},[_c('feather-icon',{attrs:{"icon":"AlertCircleIcon"}})],1),_c('div',{staticClass:"position-relative d-inline text-white"},[_vm._v(" Registre sucursales para asociar dispositivos a una sucursal del cliente ")])])]),_c('b-col',{attrs:{"lg":"8","md":"10","sm":"12"}},[_c('b-form-group',{attrs:{"label":"Tipo de dispositivo","label-for":"device"}},[_c('validation-provider',{attrs:{"name":"tipo de dispositivo","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"state":errors.length > 0 ? false:null,"dir":'ltr',"options":_vm.rowsDevices,"label":"full_name"},model:{value:(_vm.device),callback:function ($$v) {_vm.device=$$v},expression:"device"}},[(_vm.rowsDevices.length > 0)?_c('span',{attrs:{"slot":"no-options"},slot:"no-options"},[_vm._v("No hay data para cargar")]):_vm._e()]),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"lg":"8","md":"10","sm":"12"}},[_c('b-form-group',{attrs:{"label":"Marca","label-for":"brand"}},[_c('validation-provider',{attrs:{"name":"marca","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"state":errors.length > 0 ? false:null,"dir":'ltr',"options":_vm.rowsBrands,"label":"full_name"},model:{value:(_vm.brand),callback:function ($$v) {_vm.brand=$$v},expression:"brand"}},[(_vm.rowsBrands.length > 0)?_c('span',{attrs:{"slot":"no-options"},slot:"no-options"},[_vm._v("No hay data para cargar")]):_vm._e()]),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"lg":"8","md":"10","sm":"12"}},[_c('b-form-group',{attrs:{"label":"IP","label-for":"ip"}},[_c('validation-provider',{attrs:{"name":"ip","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"ip","placeholder":"IP"},model:{value:(_vm.ip),callback:function ($$v) {_vm.ip=$$v},expression:"ip"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"lg":"8","md":"10","sm":"12"}},[_c('b-form-group',{attrs:{"label":"Nombre","label-for":"name"}},[_c('validation-provider',{attrs:{"name":"name","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"name","placeholder":"Nombre"},model:{value:(_vm.name),callback:function ($$v) {_vm.name=$$v},expression:"name"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"lg":"8","md":"10","sm":"12"}},[_c('b-form-group',{attrs:{"label":"Características","label-for":"features"}})],1),_c('b-col',{attrs:{"lg":"8","md":"10","sm":"12"}},[_c('b-button',{staticClass:"float-right text-right",attrs:{"variant":"primary","type":"submit"},on:{"click":function($event){$event.preventDefault();return _vm.onHandleValidationForm.apply(null, arguments)}}},[_c('feather-icon',{staticClass:"mr-50",attrs:{"icon":"SaveIcon"}}),_vm._v(" Guardar ")],1),_c('b-button',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(255, 255, 255, 0.15)'),expression:"'rgba(255, 255, 255, 0.15)'",modifiers:{"400":true}}],staticClass:"float-right mr-1",attrs:{"variant":"secondary"},on:{"click":_vm.closeForm}},[_vm._v(" Cancelar ")])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }