var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('validation-observer',{ref:"formValidation"},[_c('b-form',[_c('b-row',[_c('b-col',{attrs:{"sm":"12"}},[_c('div',{staticClass:"alert bg-primary p-1"},[_c('div',{staticClass:"position-relative d-inline text-white"},[_c('feather-icon',{attrs:{"icon":"AlertCircleIcon"}})],1),_c('div',{staticClass:"position-relative d-inline text-white"},[_vm._v(" Registre sucursales para asociar dispositivos a una sucursal del cliente ")])])]),_c('b-col',{attrs:{"sm":"12"}},[_c('b-form-group',{attrs:{"label":"Nombre del servidor","label-for":"server_name"}},[_c('validation-provider',{attrs:{"name":"nombre del servidor","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"server_name","placeholder":"Nombre del servidor"},model:{value:(_vm.server_name),callback:function ($$v) {_vm.server_name=$$v},expression:"server_name"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"lg":"6","md":"6","sm":"12"}},[_c('b-form-group',{attrs:{"label":"Ip pública","label-for":"public_ip"}},[_c('validation-provider',{attrs:{"name":"Ip pública","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"public_ip","placeholder":"Ip pública"},model:{value:(_vm.public_ip),callback:function ($$v) {_vm.public_ip=$$v},expression:"public_ip"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"lg":"6","md":"6","sm":"12"}},[_c('b-form-group',{attrs:{"label":"Ip privada","label-for":"private_ip"}},[_c('validation-provider',{attrs:{"name":"Ip privada","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"private_ip","placeholder":"Ip privada"},model:{value:(_vm.private_ip),callback:function ($$v) {_vm.private_ip=$$v},expression:"private_ip"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"lg":"6","md":"6","sm":"12"}},[_c('b-form-group',{attrs:{"label":"Sistema operativo","label-for":"so"}},[_c('validation-provider',{attrs:{"name":"sistema operativo","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"so","placeholder":"Sistema operativo"},model:{value:(_vm.so),callback:function ($$v) {_vm.so=$$v},expression:"so"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"lg":"6","md":"6","sm":"12"}},[_c('b-form-group',{attrs:{"label":"Memoria","label-for":"memory"}},[_c('validation-provider',{attrs:{"name":"memoria","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"memory","placeholder":"Memoria"},model:{value:(_vm.memory),callback:function ($$v) {_vm.memory=$$v},expression:"memory"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"lg":"6","md":"6","sm":"12"}},[_c('b-form-group',{attrs:{"label":"Disco","label-for":"disc"}},[_c('validation-provider',{attrs:{"name":"disco","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"disc","placeholder":"Disco"},model:{value:(_vm.disc),callback:function ($$v) {_vm.disc=$$v},expression:"disc"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"lg":"6","md":"6","sm":"12"}},[_c('b-form-group',{attrs:{"label":"Modelo del servidor","label-for":"server_model"}},[_c('validation-provider',{attrs:{"name":"modelo del servidor","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"server_model","placeholder":"Modelo del servidor"},model:{value:(_vm.server_model),callback:function ($$v) {_vm.server_model=$$v},expression:"server_model"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"lg":"12","md":"12","sm":"12"}},[_c('b-form-group',{attrs:{"label":"RDWeb","label-for":"rd_web"}},[_c('validation-provider',{attrs:{"name":"rdweb","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"rd_web","placeholder":"RDWeb"},model:{value:(_vm.rd_web),callback:function ($$v) {_vm.rd_web=$$v},expression:"rd_web"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"lg":"12","md":"12","sm":"12"}},[_c('b-form-group',{attrs:{"label":"Notas","label-for":"notes"}},[_c('b-form-textarea',{attrs:{"id":"notes","placeholder":"Notas","rows":"3"},model:{value:(_vm.notes),callback:function ($$v) {_vm.notes=$$v},expression:"notes"}})],1)],1),_c('b-col',{attrs:{"lg":"8","md":"10","sm":"12"}},[_c('b-button',{staticClass:"float-right text-right",attrs:{"variant":"primary","type":"submit"},on:{"click":function($event){$event.preventDefault();return _vm.onHandleValidationForm.apply(null, arguments)}}},[_c('feather-icon',{staticClass:"mr-50",attrs:{"icon":"SaveIcon"}}),_vm._v(" Guardar ")],1),_c('b-button',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(255, 255, 255, 0.15)'),expression:"'rgba(255, 255, 255, 0.15)'",modifiers:{"400":true}}],staticClass:"float-right mr-1",attrs:{"variant":"secondary"},on:{"click":_vm.closeForm}},[_vm._v(" Cancelar ")])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }