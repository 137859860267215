<template>
  <b-row>
    <b-col cols="12">
      <DocumentComponent />
    </b-col>
  </b-row>
</template>

<script>
import { BCol, BRow } from 'bootstrap-vue'
import DocumentComponent from './Document/DocumentComponent.vue'

export default {
  name: 'TabDocumentComponent',
  components: {
    BRow,
    BCol,
    DocumentComponent,
  },
}
</script>

<style scoped>

</style>
