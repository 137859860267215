<template>
  <b-row>
    <b-col cols="12">
      <NoteComponent />
    </b-col>
  </b-row>
</template>

<script>
import { BCol, BRow } from 'bootstrap-vue'
import NoteComponent from './Note/NoteComponent.vue'

export default {
  name: 'TabNoteComponent',
  components: {
    BRow,
    BCol,
    NoteComponent,
  },
}
</script>

<style scoped>

</style>
