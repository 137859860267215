<template>
  <validation-observer ref="formValidation">
    <b-form>
      <b-row class="align-content-center mx-auto d-flex flex-column">
        <b-col
          lg="8"
          md="10"
          sm="12"
        >
          <div class="alert bg-primary p-1">
            <div class="position-relative d-inline text-white">
              <feather-icon icon="AlertCircleIcon" />
            </div>
            <div class="position-relative d-inline text-white">
              Registre sucursales para asociar dispositivos a una sucursal del cliente
            </div>
          </div>
        </b-col>
        <b-col
          lg="8"
          md="10"
          sm="12"
        >
          <b-form-group
            label="Proveedor de correo"
            label-for="prvider"
          >
            <validation-provider
              #default="{ errors }"
              name="proveedor de correo"
              rules="required"
            >
              <v-select
                v-model="provider"
                :state="errors.length > 0 ? false:null"
                :dir="'ltr'"
                :options="rowsProvides"
                label="full_name"
              >
                <span
                  v-if="rowsProvides.length > 0"
                  slot="no-options"
                >No hay data para cargar</span>
              </v-select>
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-col>
        <b-col
          lg="8"
          md="10"
          sm="12"
        >
          <b-form-group
            label="Nombre del servidor"
            label-for="server_name"
          >
            <validation-provider
              #default="{ errors }"
              name="nombre del servidor"
              rules="required"
            >
              <b-form-input
                id="server_name"
                v-model="server_name"
                placeholder="Nombre del servidor"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-col>
        <b-col
          lg="8"
          md="10"
          sm="12"
        >
          <b-form-group
            label="Email"
            label-for="email"
          >
            <validation-provider
              #default="{ errors }"
              name="email"
              rules="required|email"
            >
              <b-form-input
                v-model="emailValue"
                :state="errors.length > 0 ? false:null"
                type="email"
                placeholder="Email"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-col>
        <b-col
          lg="8"
          md="10"
          sm="12"
        >
          <b-form-group>
            <div class="d-flex justify-content-between">
              <label>Contraseña</label>
            </div>
            <validation-provider
              #default="{ errors }"
              name="contraseña"
              rules="required"
            >
              <b-input-group
                class="input-group-merge"
              >
                <b-form-input
                  v-model="password"
                  class="form-control-merge"
                  :type="passwordFieldType"
                  name="password"
                  placeholder="············"
                />
                <b-input-group-append is-text>
                  <feather-icon
                    class="cursor-pointer"
                    :icon="passwordToggleIcon"
                    @click="togglePasswordVisibility"
                  />
                </b-input-group-append>
              </b-input-group>
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-col>
        <b-col
          lg="8"
          md="10"
          sm="12"
        >
          <b-form-group
            label="Nombre (opcional)"
            label-for="name"
          >
            <validation-provider
              #default="{ errors }"
              name="name"
              rules="required"
            >
              <b-form-input
                id="name"
                v-model="name"
                placeholder="Nombre"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-col>
        <b-col
          lg="8"
          md="10"
          sm="12"
        >
          <b-form-group
            label="Notas"
            label-for="notes"
          >
            <b-form-textarea
              id="notes"
              v-model="notes"
              placeholder="Notas"
              rows="3"
            />
          </b-form-group>
        </b-col>
        <b-col
          lg="8"
          md="10"
          sm="12"
        >
          <b-button
            class="float-right text-right"
            variant="primary"
            type="submit"
            @click.prevent="onHandleValidationForm"
          >
            <feather-icon
              icon="SaveIcon"
              class="mr-50"
            />
            Guardar
          </b-button>
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="secondary"
            class="float-right mr-1"
            @click="closeForm"
          >
            Cancelar
          </b-button>
        </b-col>
      </b-row>
    </b-form>
  </validation-observer>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  BRow,
  BCol,
  BButton,
  BFormGroup,
  BFormInput,
  BForm,
  BFormTextarea,
  BInputGroupAppend,
  BInputGroup,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import { togglePasswordVisibility } from '@core/mixins/ui/forms'
import { required, email } from '@validations'
import errorsServices from '@/libs/errorsServices'
import permissions from '@/libs/permissions'

export default {
  name: 'MailFormComponent',
  components: {
    vSelect,
    ValidationProvider,
    ValidationObserver,
    BRow,
    BCol,
    BButton,
    BFormGroup,
    BFormInput,
    BForm,
    BFormTextarea,
    BInputGroupAppend,
    BInputGroup,
  },
  mixins: [togglePasswordVisibility],
  data() {
    return {
      provider: '',
      server_name: '',
      emailValue: '',
      password: '',
      name: '',
      notes: '',
      rowsProvides: [],
      required,
      email,
    }
  },
  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
  },
  created() {
    this.onHandleEdit()
  },
  methods: {
    accessPermissions(permission) {
      return permissions(permission)
    },
    errorResp(error) {
      const err = errorsServices(error)

      this.swal(err, 'error')
    },
    clearForm() {
      this.device = ''
      this.server_name = ''
      this.emailValue = ''
      this.password = ''
      this.name = ''
      this.notes = ''
      this.rowsProvides = []
      this.$refs.formValidation.reset()
    },
    closeForm() {
      this.clearForm()
      this.$emit('setHiddenModal')
    },
    onHandleEdit() {

    },
    async onHandleValidationForm() {
      await this.$refs.formValidation.validate().then(success => {
        if (success) {
        }
      })
    },
  },
}
</script>

<style scoped>

</style>