<template>
  <b-row>
    <b-col cols="12">
      <b-tabs justified>
        <b-tab title="Datos">
          <DataComponent />
        </b-tab>

        <b-tab title="Sucursales">
          <BranchComponent />
        </b-tab>

        <b-tab title="Contactos">
          <ContactComponent />
        </b-tab>

        <b-tab title="Proveedores">
          <ProviderComponent />
        </b-tab>

        <b-tab title="Usuarios">
          <UserComponent />
        </b-tab>
      </b-tabs>
    </b-col>
  </b-row>
</template>

<script>
import {
  BRow,
  BCol,
  BTabs,
  BTab,
} from 'bootstrap-vue'
import DataComponent from './GeneralData/Data/DataComponent.vue'
import BranchComponent from './GeneralData/Branch/BranchComponent.vue'
import ContactComponent from './GeneralData/Contact/ContactComponent.vue'
import ProviderComponent from './GeneralData/Provider/ProviderComponent.vue'
import UserComponent from './GeneralData/User/UserComponent.vue'

export default {
  name: 'TabGeneralDataComponent',
  components: {
    BRow,
    BCol,
    BTabs,
    BTab,
    DataComponent,
    BranchComponent,
    ContactComponent,
    ProviderComponent,
    UserComponent,
  },
}
</script>

<style scoped>

</style>