<template>
  <div>
    <b-row>
      <b-col cols="6">
        <div class="custom-search d-flex justify-content-start">
          <b-button
            v-b-modal.modal-users
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
          >
            <feather-icon
              icon="PlusCircleIcon"
              class="mr-50"
            />
            <span class="align-middle">Nuevo</span>
          </b-button>
        </div>
      </b-col>
      <b-col cols="6">
        <div class="custom-search d-flex justify-content-end">
          <b-form-group>
            <b-form-input
              id="filterInput"
              v-model="filter"
              type="search"
              placeholder="Buscar..."
              class="d-inline-block"
            />
          </b-form-group>
        </div>
      </b-col>
    </b-row>
    <b-row>
      <b-col cols="12">
        <b-table
          striped
          hover
          responsive
          class="position-relative"
          :per-page="perPage"
          :current-page="currentPage"
          :fields="fields"
          :items="rows"
          :filter="filter"
          @filtered="onFiltered"
        >
          <template #cell(action)="data">
            <b-dropdown
              variant="link"
              toggle-class="text-decoration-none"
              no-caret
            >
              <template v-slot:button-content>
                <feather-icon
                  icon="MoreVerticalIcon"
                  size="16"
                  class="text-body align-middle mr-25"
                />
              </template>
              <b-dropdown-item>
                <feather-icon
                  icon="Edit2Icon"
                  class="mr-50"
                />
                <span>Editar</span>
              </b-dropdown-item>
              <b-dropdown-item>
                <feather-icon
                  icon="TrashIcon"
                  class="mr-50"
                />
                <span>Eliminar</span>
              </b-dropdown-item>
            </b-dropdown>
          </template>
        </b-table>
      </b-col>
    </b-row>
    <b-row>
      <b-col cols="6">
        <div class="custom-search d-flex justify-content-start">
          <b-form-group
            label="Por Página"
            label-cols="6"
            label-align="left"
            label-size="sm"
            label-for="sortBySelect"
            class="text-nowrap mb-md-0 mr-1"
          >
            <b-form-select
              id="perPageSelect"
              v-model="perPage"
              size="sm"
              inline
              :options="pageOptions"
            />
          </b-form-group>
        </div>
      </b-col>
      <b-col cols="6">
        <div class="custom-search d-flex justify-content-end">
          <b-pagination
            v-model="currentPage"
            :total-rows="totalRows"
            :per-page="perPage"
            first-number
            last-number
            prev-class="prev-item"
            next-class="next-item"
            class="mb-0"
          >
            <template #prev-text>
              <feather-icon
                icon="ChevronLeftIcon"
                size="18"
              />
            </template>
            <template #next-text>
              <feather-icon
                icon="ChevronRightIcon"
                size="18"
              />
            </template>
          </b-pagination>
        </div>
      </b-col>
    </b-row>
    <b-modal
      id="modal-users"
      ref="users-modal"
      centered
      hide-backdrop
      hide-footer
      no-close-on-backdrop
      content-class="shadow"
      size="lg"
      title="Agregar un nuevo usuario"
    >
      <b-card-body>
        <UserFormComponent
          @setHiddenModal="hide"
        />
      </b-card-body>
    </b-modal>
  </div>
</template>

<script>
import {
  BCardBody,
  BRow,
  BCol,
  BButton,
  BFormGroup,
  BPagination,
  BFormInput,
  BFormSelect,
  BTable,
  BModal,
} from 'bootstrap-vue'
import UserFormComponent from './UserFormComponent.vue'
import errorsServices from '@/libs/errorsServices'
import permissions from '@/libs/permissions'

export default {
  name: 'UserComponent',
  components: {
    BCardBody,
    BRow,
    BCol,
    BButton,
    BFormGroup,
    BPagination,
    BFormInput,
    BFormSelect,
    BTable,
    BModal,
    UserFormComponent,
  },
  data() {
    return {
      perPage: 50,
      pageOptions: [10, 20, 50, 100],
      totalRows: 0,
      currentPage: 1,
      filter: null,
      fields: [
        {
          key: 'full_name', label: 'Nombre completo',
        },
        {
          key: 'email', label: 'Correo',
        },
        {
          key: 'user', label: 'Usuario',
        },
        {
          key: 'password', label: 'Password',
        },
        {
          key: 'notes', label: 'Notas',
        },
        {
          key: 'action', label: 'Acción',
        },
      ],
      rows: [],
    }
  },
  async created() {
    await this.onHandleList()
  },
  methods: {
    accessPermissions(permission) {
      return permissions(permission)
    },
    errorResp(error) {
      const err = errorsServices(error)

      this.swal(err, 'error')
    },
    swal(text, type) {
      this.$swal({
        title: text,
        icon: type,
        showCancelButton: false,
        showConfirmButton: true,
        confirmButtonText: 'Aceptar',
        customClass: {
          confirmButton: 'btn btn-primary',
        },
        showClass: {
          popup: 'animate__animated animate__bounceIn',
        },
        buttonsStyling: false,
      })
    },
    hide() {
      this.onHandleList()
      this.$refs['users-modal'].hide()
    },
    onFiltered(filteredItems) {
      this.totalRows = filteredItems.length
      this.currentPage = 1
    },
    onHandleList() {

    },
  },
}
</script>

<style scoped>

</style>
