<template>
  <validation-observer ref="formValidation">
    <b-form>
      <b-row class="align-content-center mx-auto d-flex flex-column">
        <b-col
          lg="8"
          md="10"
          sm="12"
        >
          <div class="alert bg-primary p-1">
            <div class="position-relative d-inline text-white">
              <feather-icon icon="AlertCircleIcon" />
            </div>
            <div class="position-relative d-inline text-white">
              Si el proveedor que quiere asociar no se encuentra registrado, marque el checbox y registrelo aquí.
            </div>
          </div>
        </b-col>
        <b-col
          lg="8"
          md="10"
          sm="12"
        >
          <b-form-group
            label=""
            label-for="has_register"
          >
            <b-form-checkbox
              v-model="has_register"
              class="custom-control-primary"
            >
              Registrar proveedor
            </b-form-checkbox>
          </b-form-group>
        </b-col>
        <b-col
          lg="8"
          md="10"
          sm="12"
        >
          <b-form-group
            label="Proveedor"
            label-for="provider"
          >
            <validation-provider
              #default="{ errors }"
              name="proveedor"
              rules="required"
            >
              <v-select
                v-model="provider"
                :state="errors.length > 0 ? false:null"
                :dir="'ltr'"
                :options="rowsProviders"
                label="full_name"
              >
                <span
                  v-if="rowsProviders.length > 0"
                  slot="no-options"
                >No hay data para cargar</span>
              </v-select>
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-col>
        <b-col
          lg="8"
          md="10"
          sm="12"
        >
          <b-form-group
            label="Número de contrato"
            label-for="contract_number"
          >
            <validation-provider
              #default="{ errors }"
              name="número de contrato"
              rules="required"
            >
              <b-form-input
                id="contract_number"
                v-model="contract_number"
                placeholder="Número de contrato"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-col>
        <b-col
          lg="8"
          md="10"
          sm="12"
        >
          <b-form-group
            label="Comentario"
            label-for="comment"
          >
            <b-form-textarea
              id="comment"
              v-model="comment"
              placeholder="Comentario"
              rows="3"
            />
          </b-form-group>
        </b-col>
        <b-col
          lg="8"
          md="10"
          sm="12"
        >
          <b-button
            class="float-right text-right"
            variant="primary"
            type="submit"
            @click.prevent="onHandleValidationForm"
          >
            <feather-icon
              icon="SaveIcon"
              class="mr-50"
            />
            Guardar
          </b-button>
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="secondary"
            class="float-right mr-1"
            @click="closeForm"
          >
            Cancelar
          </b-button>
        </b-col>
      </b-row>
    </b-form>
  </validation-observer>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  BRow,
  BCol,
  BButton,
  BFormGroup,
  BFormInput,
  BForm,
  BFormTextarea,
  BFormCheckbox,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import { required } from '@validations'
import errorsServices from '@/libs/errorsServices'
import permissions from '@/libs/permissions'

export default {
  name: 'ProviderFormComponent',
  components: {
    vSelect,
    ValidationProvider,
    ValidationObserver,
    BRow,
    BCol,
    BButton,
    BFormGroup,
    BFormInput,
    BForm,
    BFormTextarea,
    BFormCheckbox,
  },
  data() {
    return {
      has_register: false,
      provider: '',
      contract_number: '',
      comment: '',
      rowsProviders: [],
      required,
    }
  },
  created() {
    this.onHandleEdit()
  },
  methods: {
    accessPermissions(permission) {
      return permissions(permission)
    },
    errorResp(error) {
      const err = errorsServices(error)

      this.swal(err, 'error')
    },
    clearForm() {
      this.has_register = false
      this.provider = ''
      this.contract_number = ''
      this.comment = ''
      this.rowsProviders = []
      this.$refs.formValidation.reset()
    },
    closeForm() {
      this.clearForm()
      this.$emit('setHiddenModal')
    },
    onHandleEdit() {

    },
    async onHandleValidationForm() {
      await this.$refs.formValidation.validate().then(success => {
        if (success) {
        }
      })
    },
  },
}
</script>

<style scoped>

</style>